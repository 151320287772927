import {
  BusinessModelIcon,
  LeafIcon,
  MoneyBagIcon,
  ShuffleIcon,
  SlidersIcon,
  StarOutlinedIcon,
  TrendingUpIcon,
  UserClockIcon,
} from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import { ratingEsgMap, ratingInvestmentCriteriaMap } from '@gain/utils/investment-criteria'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import { AIExplainerFinancial } from '../../ai-explainer/ai-explainer-dialog'
import FilterAiFinancialExplainer from '../../ai-explainer/filter-ai-financial-explainer'
import {
  advisor,
  asset,
  businessActivity,
  checkbox,
  createFilterMap,
  customerBase,
  dealRoundType,
  FilterModel,
  FilterSelectConfig,
  FilterSwitchConfig,
  FilterTabConfig,
  filterValueGroup,
  filterValueItem,
  fteRangeCategory,
  geoPoint,
  geoPolygon,
  investor,
  ownership,
  pricePositioning,
  range,
  rangeCurrency,
  rangeMultiple,
  rangePercentage,
  rangeYear,
  rating,
  region,
  salesChannel,
  sector,
  tag,
  usState,
} from '../../filter/filter-bar'
import {
  FilterConfig,
  FilterZoomInConfig,
} from '../../filter/filter-bar/filter-config/filter-config-model'

export const ASSET_FILTER_FIELDS = [
  'relevanceRank',
  'tagIds',
  'region',
  'subsector',
  'revenueEur',
  'revenueWithAiGeneratedEur',
  'grossMarginEur',
  'grossMarginPctRevenue',
  'ebitdaEur',
  'ebitdaWithAiGeneratedEur',
  'ebitdaPctRevenue',
  'ebitdaPctRevenueWithAiGenerated',
  'ebitEur',
  'ebitPctRevenue',
  'totalAssetsEur',
  'capexEur',
  'netDebtEur',
  'debtEur',
  'cashEur',
  'capitalEur',
  'inventoryEur',
  'receivablesEur',
  'payablesEur',
  'enterpriseValueEur',
  'marketCapitalizationEur',
  'customerBase',
  'businessActivity',
  'salesChannel',
  'pricePositioning',
  'fteRangeCategory',
  'fteGrowthPctThreeMonths',
  'fteGrowthPctSixMonths',
  'fteGrowthPctOneYear',
  'fteCagrPctTwoYears',
  'fteCagrPctThreeYears',
  'revenueGrowthPctOneYear',
  'revenueCagrPctTwoYears',
  'revenueCagrPctThreeYears',
  'grossMarginGrowthPctOneYear',
  'grossMarginCagrPctTwoYears',
  'grossMarginCagrPctThreeYears',
  'ebitdaGrowthPctOneYear',
  'ebitdaCagrPctTwoYears',
  'ebitdaCagrPctThreeYears',
  'ebitGrowthPctOneYear',
  'ebitCagrPctTwoYears',
  'ebitCagrPctThreeYears',
  'revenueFteRatioEur',
  'netDebtEbitdaRatio',
  'enterpriseValueEbitdaRatio',
  'enterpriseValueEbitRatio',
  'enterpriseValueRevenueRatio',
  'enterpriseValueEbitdaMinusCapexRatio',
  'enterpriseValueInvestedCapitalRatio',
  'enterpriseValueFreeCashFlowRatio',
  'returnOnAssets',
  'returnOnEquity',
  'returnOnCapitalEmployed',
  'ebitdaMinusCapexEur',
  'totalDealFundingRaisedEur',
  'latestDealRoundSizeEur',
  'latestDealPreMoneyValuationEur',
  'latestDealPostMoneyValuationEur',
  'latestDealRoundType',
  'addOnDealCountL5Y',
  'ceoAge',
  'lastDealYear',
  'ownership',
  'yearFounded',
  'ownerIds',
  'advisorIds',
  'predictedExitEbitdaEur',
  'predictedExitMultiple',
  'predictedExitEvEur',
  'predictedExitYear',
  'ratingGrowth',
  'ratingOrganicGrowth',
  'ratingGrossMargin',
  'ratingEbitda',
  'ratingConversion',
  'ratingNonCyclical',
  'ratingContracted',
  'ratingLeader',
  'ratingMultinational',
  'ratingBuyAndBuild',
  'latestIndustryRatingOverall',
  'latestIndustryRatingEnvironmental',
  'latestIndustryRatingSocial',
  'esgOutperformer',
  'headquartersWgs84LngLat',
  'headquartersRegion',
  'headquartersFormattedAddress',
] as const

export type AssetFilterField = (typeof ASSET_FILTER_FIELDS)[number]

const HQ_LOCATION_TABS: FilterTabConfig<AssetListItem, AssetFilterField> = {
  options: [
    { id: 'region', label: 'By country' },
    { id: 'headquartersFormattedAddress', label: 'By area' },
    { id: 'headquartersWgs84LngLat', label: 'By radius' },
  ],
}

// One time hack to enable filtering on US-states from the region (country
// checkbox list) filter. THIS FUNCTIONALITY SHOULD NOT BE RE-USED!!!
const HQ_LOCATION_ZOOM_IN: FilterZoomInConfig<AssetListItem, AssetFilterField> = {
  backId: 'region',
  backLabel: 'Back to countries',
  options: [{ id: 'headquartersRegion', value: 'US', label: 'Select state' }],
}

const FTE_GROWTH_SELECT: FilterSelectConfig<AssetListItem, AssetFilterField> = {
  header: 'FTE growth',
  options: [
    { id: 'fteGrowthPctThreeMonths', label: '3-month' },
    { id: 'fteGrowthPctSixMonths', label: '6-month' },
    { id: 'fteGrowthPctOneYear', label: '1-year' },
    { id: 'fteCagrPctTwoYears', label: '2-year CAGR' },
    { id: 'fteCagrPctThreeYears', label: '3-year CAGR' },
  ],
}

const REVENUE_GROWTH_SELECT: FilterSelectConfig<AssetListItem, AssetFilterField> = {
  header: 'Revenue growth',
  options: [
    { id: 'revenueGrowthPctOneYear', label: '1-year' },
    { id: 'revenueCagrPctTwoYears', label: '2-year CAGR' },
    { id: 'revenueCagrPctThreeYears', label: '3-year CAGR' },
  ],
}

const GROSS_MARGIN_GROWTH_SELECT: FilterSelectConfig<AssetListItem, AssetFilterField> = {
  header: 'Gross margin growth',
  options: [
    { id: 'grossMarginGrowthPctOneYear', label: '1-year' },
    { id: 'grossMarginCagrPctTwoYears', label: '2-year CAGR' },
    { id: 'grossMarginCagrPctThreeYears', label: '3-year CAGR' },
  ],
}

const EBITDA_GROWTH_SELECT: FilterSelectConfig<AssetListItem, AssetFilterField> = {
  header: 'EBITDA growth',
  options: [
    { id: 'ebitdaGrowthPctOneYear', label: '1-year' },
    { id: 'ebitdaCagrPctTwoYears', label: '2-year CAGR' },
    { id: 'ebitdaCagrPctThreeYears', label: '3-year CAGR' },
  ],
}

const EBIT_GROWTH_SELECT: FilterSelectConfig<AssetListItem, AssetFilterField> = {
  header: 'EBIT growth',
  options: [
    { id: 'ebitGrowthPctOneYear', label: '1-year' },
    { id: 'ebitCagrPctTwoYears', label: '2-year CAGR' },
    { id: 'ebitCagrPctThreeYears', label: '3-year CAGR' },
  ],
}

const REVENUE_SWITCH: FilterSwitchConfig<AssetListItem, AssetFilterField> = {
  header: 'Include AI estimates',
  explainer: <FilterAiFinancialExplainer financial={AIExplainerFinancial.Revenue} />,
  options: [
    { id: 'revenueEur', label: 'no AI generated', checked: false },
    { id: 'revenueWithAiGeneratedEur', label: 'AI generated', checked: true },
  ],
}

const EBITDA_SWITCH: FilterSwitchConfig<AssetListItem, AssetFilterField> = {
  header: 'Include AI estimates',
  explainer: <FilterAiFinancialExplainer financial={AIExplainerFinancial.EBITDA} />,
  options: [
    { id: 'ebitdaEur', label: 'no AI generated', checked: false },
    { id: 'ebitdaWithAiGeneratedEur', label: 'AI generated', checked: true },
  ],
}

const EBITDA_PERCENTAGE_SWITCH: FilterSwitchConfig<AssetListItem, AssetFilterField> = {
  header: 'Include AI estimates',
  explainer: <FilterAiFinancialExplainer financial={AIExplainerFinancial.EBITDA} />,
  options: [
    { id: 'ebitdaPctRevenue', label: 'no AI generated', checked: false },
    { id: 'ebitdaPctRevenueWithAiGenerated', label: 'AI generated', checked: true },
  ],
}

export const ASSET_FILTER_MAP = createFilterMap<AssetListItem, typeof ASSET_FILTER_FIELDS>(
  asset('relevanceRank', 'Similar to', {
    disableDelete: true,
    disableToggleIncludeMode: true,
  }),
  tag('tagIds', 'Tags'),
  region('region', 'Company HQ', { tabs: HQ_LOCATION_TABS, zoomIn: HQ_LOCATION_ZOOM_IN }),
  sector('subsector', 'Sector'),

  // Financials
  rangeCurrency('revenueEur', 'Revenue', { switch: REVENUE_SWITCH }),
  rangeCurrency('revenueWithAiGeneratedEur', 'Revenue', { switch: REVENUE_SWITCH }),
  rangeCurrency('grossMarginEur', 'Gross margin'),
  rangePercentage('grossMarginPctRevenue', 'Gross margin %'),
  rangeCurrency('ebitdaEur', 'EBITDA', { switch: EBITDA_SWITCH }),
  rangeCurrency('ebitdaWithAiGeneratedEur', 'EBITDA', { switch: EBITDA_SWITCH }),
  rangePercentage('ebitdaPctRevenue', 'EBITDA %', { switch: EBITDA_PERCENTAGE_SWITCH }),
  rangePercentage('ebitdaPctRevenueWithAiGenerated', 'EBITDA %', {
    switch: EBITDA_PERCENTAGE_SWITCH,
  }),
  rangeCurrency('ebitEur', 'EBIT'),
  rangePercentage('ebitPctRevenue', 'EBIT %'),
  rangeCurrency('totalAssetsEur', 'Total assets'),
  rangeCurrency('capexEur', 'CAPEX'),

  // Financials/Net debt
  rangeCurrency('netDebtEur', 'Net debt'),
  rangeCurrency('debtEur', 'Interest-bearing debt'),
  rangeCurrency('cashEur', 'Cash & cash equivalents'),

  // Financials/Trade working capital
  rangeCurrency('capitalEur', 'Trade working capital'),
  rangeCurrency('inventoryEur', 'Inventory'),
  rangeCurrency('receivablesEur', 'Receivables'),
  rangeCurrency('payablesEur', 'Payables'),
  rangeCurrency('enterpriseValueEur', 'Enterprise value'),
  rangeCurrency('marketCapitalizationEur', 'Market cap'),
  customerBase('customerBase', 'Customer base'),
  businessActivity('businessActivity', 'Business activity'),
  salesChannel('salesChannel', 'Sales channel'),
  pricePositioning('pricePositioning', 'Price positioning'),

  // FTE
  fteRangeCategory('fteRangeCategory', 'FTEs'),

  // Growth
  rangePercentage('fteGrowthPctThreeMonths', '3M FTE growth', { select: FTE_GROWTH_SELECT }),
  rangePercentage('fteGrowthPctSixMonths', '6M FTE growth', { select: FTE_GROWTH_SELECT }),
  rangePercentage('fteGrowthPctOneYear', '1Y FTE growth', { select: FTE_GROWTH_SELECT }),
  rangePercentage('fteCagrPctTwoYears', '2Y FTE CAGR', { select: FTE_GROWTH_SELECT }),
  rangePercentage('fteCagrPctThreeYears', '3Y FTE CAGR', { select: FTE_GROWTH_SELECT }),
  rangePercentage('revenueGrowthPctOneYear', '1Y revenue growth', {
    select: REVENUE_GROWTH_SELECT,
  }),
  rangePercentage('revenueCagrPctTwoYears', '2Y revenue CAGR', { select: REVENUE_GROWTH_SELECT }),
  rangePercentage('revenueCagrPctThreeYears', '3Y revenue CAGR', { select: REVENUE_GROWTH_SELECT }),
  rangePercentage('grossMarginGrowthPctOneYear', '1Y gross margin growth', {
    select: GROSS_MARGIN_GROWTH_SELECT,
  }),
  rangePercentage('grossMarginCagrPctTwoYears', '2Y gross margin CAGR', {
    select: GROSS_MARGIN_GROWTH_SELECT,
  }),
  rangePercentage('grossMarginCagrPctThreeYears', '3Y gross margin CAGR', {
    select: GROSS_MARGIN_GROWTH_SELECT,
  }),
  rangePercentage('ebitdaGrowthPctOneYear', '1Y EBITDA growth', { select: EBITDA_GROWTH_SELECT }),
  rangePercentage('ebitdaCagrPctTwoYears', '2Y EBITDA CAGR', { select: EBITDA_GROWTH_SELECT }),
  rangePercentage('ebitdaCagrPctThreeYears', '3Y EBITDA CAGR', { select: EBITDA_GROWTH_SELECT }),
  rangePercentage('ebitGrowthPctOneYear', '1Y EBIT growth', { select: EBIT_GROWTH_SELECT }),
  rangePercentage('ebitCagrPctTwoYears', '2Y EBIT CAGR', { select: EBIT_GROWTH_SELECT }),
  rangePercentage('ebitCagrPctThreeYears', '3Y EBIT CAGR', { select: EBIT_GROWTH_SELECT }),

  // Ratios
  rangeCurrency('revenueFteRatioEur', 'Revenue / FTE', { isMillions: false }),
  rangeMultiple('netDebtEbitdaRatio', 'Net debt / EBITDA'),

  rangeMultiple('enterpriseValueEbitdaRatio', 'EV / EBITDA'),
  rangeMultiple('enterpriseValueEbitRatio', 'EV / EBIT'),
  rangeMultiple('enterpriseValueRevenueRatio', 'EV / Sales'),
  rangeMultiple('enterpriseValueEbitdaMinusCapexRatio', 'EV / (EBITDA - CAPEX)'),
  rangeMultiple('enterpriseValueInvestedCapitalRatio', 'EV / Invested Capital'),
  rangeMultiple('enterpriseValueFreeCashFlowRatio', 'EV / Free Cash Flow'),

  rangePercentage('returnOnAssets', 'Return on assets'),
  rangePercentage('returnOnEquity', 'Return on equity'),
  rangePercentage('returnOnCapitalEmployed', 'Return on capital employed'),

  rangeCurrency('ebitdaMinusCapexEur', 'EBITDA - CAPEX'),

  // Funding
  rangeCurrency('totalDealFundingRaisedEur', 'Total funding'),
  rangeCurrency('latestDealRoundSizeEur', 'Latest round size'),
  rangeCurrency('latestDealPreMoneyValuationEur', 'Latest pre-money valuation'),
  rangeCurrency('latestDealPostMoneyValuationEur', 'Latest post-money valuation'),
  dealRoundType('latestDealRoundType', 'Latest round'),
  range('addOnDealCountL5Y', 'Number of add-ons (L5Y)', 'number', { placeholder: ['Min', 'Max'] }),

  range('ceoAge', 'CEO age', 'number', { suffix: 'yr', step: 1, icon: UserClockIcon }),
  rangeYear('lastDealYear', 'Last platform deal'),
  ownership('ownership', 'Ownership'),
  rangeYear('yearFounded', 'Year founded'),
  investor('ownerIds', 'Investors'),
  advisor('advisorIds', 'Advisors'),

  // Next deal
  rangeCurrency('predictedExitEbitdaEur', 'Predicted EBITDA'),
  rangeMultiple('predictedExitMultiple', 'Predicted multiple'),
  rangeCurrency('predictedExitEvEur', 'Predicted EV'),
  rangeYear('predictedExitYear', 'Predicted exit time'),

  // Investment criteria
  rating('ratingGrowth', ratingInvestmentCriteriaMap.ratingGrowth, {
    label: 'Revenue growth rating',
  }),
  rating('ratingOrganicGrowth', ratingInvestmentCriteriaMap.ratingOrganicGrowth, {
    label: 'Organic growth rating',
  }),
  rating('ratingGrossMargin', ratingInvestmentCriteriaMap.ratingGrossMargin, {
    label: 'Gross margin rating',
  }),
  rating('ratingEbitda', ratingInvestmentCriteriaMap.ratingEbitda, {
    label: 'EBITDA margin rating',
  }),
  rating('ratingConversion', ratingInvestmentCriteriaMap.ratingConversion),
  rating('ratingNonCyclical', ratingInvestmentCriteriaMap.ratingNonCyclical),
  rating('ratingContracted', ratingInvestmentCriteriaMap.ratingContracted),
  rating('ratingLeader', ratingInvestmentCriteriaMap.ratingLeader),
  rating('ratingMultinational', ratingInvestmentCriteriaMap.ratingMultinational),
  rating('ratingBuyAndBuild', ratingInvestmentCriteriaMap.ratingBuyAndBuild),

  // ESG
  rating('latestIndustryRatingOverall', ratingEsgMap.ratingOverall),
  rating('latestIndustryRatingEnvironmental', ratingEsgMap.ratingEnvironmental),
  rating('latestIndustryRatingSocial', ratingEsgMap.ratingSocial),
  checkbox('esgOutperformer', 'ESG outperformer', {
    hint: 'Proactively addresses ESG risks related to the Company and the underlying industry segment.',
  }),
  geoPoint('headquartersWgs84LngLat', 'Company HQ', { tabs: HQ_LOCATION_TABS }),
  usState('headquartersRegion', 'Company HQ', { zoomIn: HQ_LOCATION_ZOOM_IN }),
  geoPolygon('headquartersFormattedAddress', 'Company HQ', { tabs: HQ_LOCATION_TABS })
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const ASSET_FILTER_MENU: DropdownMenuOptions<FilterConfig<AssetListItem, AssetFilterField>> =
  [
    ASSET_FILTER_MAP.subsector,
    ASSET_FILTER_MAP.tagIds,
    ASSET_FILTER_MAP.region,
    ASSET_FILTER_MAP.fteRangeCategory,
    dropdownMenuGroup('financials', 'Financials', {
      icon: MoneyBagIcon,
      children: [
        ASSET_FILTER_MAP.revenueWithAiGeneratedEur,
        dropdownMenuGroup('grossMargin', 'Gross margin', {
          children: [ASSET_FILTER_MAP.grossMarginEur, ASSET_FILTER_MAP.grossMarginPctRevenue],
        }),
        dropdownMenuGroup('ebitda', 'EBITDA', {
          children: [
            ASSET_FILTER_MAP.ebitdaWithAiGeneratedEur,
            ASSET_FILTER_MAP.ebitdaPctRevenueWithAiGenerated,
          ],
        }),
        dropdownMenuGroup('ebit', 'EBIT', {
          children: [ASSET_FILTER_MAP.ebitEur, ASSET_FILTER_MAP.ebitPctRevenue],
        }),
        ASSET_FILTER_MAP.capexEur,
        ASSET_FILTER_MAP.ebitdaMinusCapexEur,
        ASSET_FILTER_MAP.totalAssetsEur,
        dropdownMenuGroup('debt', 'Debt', {
          children: [
            ASSET_FILTER_MAP.netDebtEur,
            ASSET_FILTER_MAP.debtEur,
            ASSET_FILTER_MAP.cashEur,
          ],
        }),
        dropdownMenuGroup('workingCapital', 'Working capital', {
          children: [
            ASSET_FILTER_MAP.capitalEur,
            ASSET_FILTER_MAP.inventoryEur,
            ASSET_FILTER_MAP.receivablesEur,
            ASSET_FILTER_MAP.payablesEur,
          ],
        }),
        dropdownMenuGroup('listedCompanyFinancials', 'Listed company financials', {
          children: [ASSET_FILTER_MAP.marketCapitalizationEur, ASSET_FILTER_MAP.enterpriseValueEur],
        }),
      ],
    }),
    dropdownMenuGroup('ratios', 'Ratios', {
      icon: SlidersIcon,
      children: [
        ASSET_FILTER_MAP.revenueFteRatioEur,
        ASSET_FILTER_MAP.netDebtEbitdaRatio,
        dropdownMenuGroup('returnRatios', 'Return ratios', {
          children: [
            ASSET_FILTER_MAP.returnOnAssets,
            ASSET_FILTER_MAP.returnOnEquity,
            ASSET_FILTER_MAP.returnOnCapitalEmployed,
          ],
        }),
        dropdownMenuGroup('valuationRatios', 'Valuation ratios', {
          children: [
            ASSET_FILTER_MAP.enterpriseValueEbitdaRatio,
            ASSET_FILTER_MAP.enterpriseValueEbitRatio,
            ASSET_FILTER_MAP.enterpriseValueRevenueRatio,
            ASSET_FILTER_MAP.enterpriseValueEbitdaMinusCapexRatio,
            ASSET_FILTER_MAP.enterpriseValueInvestedCapitalRatio,
            ASSET_FILTER_MAP.enterpriseValueFreeCashFlowRatio,
          ],
        }),
      ],
    }),
    dropdownMenuGroup('growth', 'Growth', {
      icon: TrendingUpIcon,
      children: [
        ASSET_FILTER_MAP.fteGrowthPctOneYear,
        ASSET_FILTER_MAP.revenueGrowthPctOneYear,
        ASSET_FILTER_MAP.grossMarginGrowthPctOneYear,
        ASSET_FILTER_MAP.ebitdaGrowthPctOneYear,
        ASSET_FILTER_MAP.ebitGrowthPctOneYear,
      ],
    }),
    ASSET_FILTER_MAP.ownership,
    ASSET_FILTER_MAP.ownerIds,
    dropdownMenuGroup('deals', 'Deals', {
      icon: ShuffleIcon,
      children: [
        dropdownMenuGroup('pastDeal', 'Past deals and funding', {
          children: [
            ASSET_FILTER_MAP.lastDealYear,
            ASSET_FILTER_MAP.totalDealFundingRaisedEur,
            ASSET_FILTER_MAP.latestDealRoundType,
            ASSET_FILTER_MAP.latestDealRoundSizeEur,
            ASSET_FILTER_MAP.latestDealPreMoneyValuationEur,
            ASSET_FILTER_MAP.latestDealPostMoneyValuationEur,
            ASSET_FILTER_MAP.addOnDealCountL5Y,
          ],
        }),
        dropdownMenuGroup('nextDeal', 'Next deal', {
          children: [
            ASSET_FILTER_MAP.predictedExitEbitdaEur,
            ASSET_FILTER_MAP.predictedExitMultiple,
            ASSET_FILTER_MAP.predictedExitEvEur,
            ASSET_FILTER_MAP.predictedExitYear,
          ],
        }),
      ],
    }),
    dropdownMenuGroup('businessModel', 'Business model', {
      icon: BusinessModelIcon,
      children: [
        ASSET_FILTER_MAP.customerBase,
        ASSET_FILTER_MAP.businessActivity,
        ASSET_FILTER_MAP.salesChannel,
        ASSET_FILTER_MAP.pricePositioning,
      ],
    }),
    dropdownMenuGroup('assessment', 'Assessment', {
      icon: StarOutlinedIcon,
      children: [
        ASSET_FILTER_MAP.ratingGrowth,
        ASSET_FILTER_MAP.ratingOrganicGrowth,
        ASSET_FILTER_MAP.ratingGrossMargin,
        ASSET_FILTER_MAP.ratingEbitda,
        ASSET_FILTER_MAP.ratingConversion,
        ASSET_FILTER_MAP.ratingNonCyclical,
        ASSET_FILTER_MAP.ratingContracted,
        ASSET_FILTER_MAP.ratingLeader,
        ASSET_FILTER_MAP.ratingMultinational,
        ASSET_FILTER_MAP.ratingBuyAndBuild,
      ],
    }),
    dropdownMenuGroup('esg', 'ESG', {
      icon: LeafIcon,
      children: [
        ASSET_FILTER_MAP.esgOutperformer,
        ASSET_FILTER_MAP.latestIndustryRatingOverall,
        ASSET_FILTER_MAP.latestIndustryRatingEnvironmental,
        ASSET_FILTER_MAP.latestIndustryRatingSocial,
      ],
    }),
    ASSET_FILTER_MAP.yearFounded,
    ASSET_FILTER_MAP.advisorIds,
    ASSET_FILTER_MAP.ceoAge,
  ]

export const ASSET_DEFAULT_FILTERS: FilterModel<AssetListItem, AssetFilterField> = [
  filterValueGroup(filterValueItem('region')),
  filterValueGroup(filterValueItem('revenueWithAiGeneratedEur')),
  filterValueGroup(filterValueItem('ebitdaWithAiGeneratedEur')),
  filterValueGroup(filterValueItem('fteRangeCategory')),
  filterValueGroup(filterValueItem('fteGrowthPctOneYear')),
  filterValueGroup(filterValueItem('ownership')),
  filterValueGroup(filterValueItem('tagIds')),
  filterValueGroup(filterValueItem('relevanceRank')),
]
