import { Asset, ConferenceEditionListItem } from '@gain/rpc/app-model'
import { formatCountry } from '@gain/utils/countries'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import { compareDesc } from 'date-fns/compareDesc'
import { format } from 'date-fns/format'
import { isFuture } from 'date-fns/isFuture'
import { isPast } from 'date-fns/isPast'
import OverflowContainer from 'libs/components/overflow-container/src/overflow-container'
import { formatDateRange } from 'libs/utils/date/src/format-date-range'
import useVisibleColumns, { ColumnVisibilityModel } from 'libs/utils/table/src/use-visible-columns'
import { memo, useRef, useState } from 'react'

import Card, { CardHeader, CardProps, CardTabs } from '../../../../common/card/card'
import Table, { ColumnConfig } from '../../../../common/table'
import Tag from '../../../../common/tag'
import ViewMoreButton from '../../../../common/view-more-button'
import ConferenceEditionContentLink from '../../../../features/conference-edition/conference-edition-content-link'
import {
  autocompleteIncludeFilterValue,
  createFilterModelQueryRoute,
  FilterModel,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import { CONFERENCE_LIST_PATH } from '../../../utils'

const TagsCell = memo(function TagsCell({ tagIds, tags }: { tagIds: number[]; tags: string[] }) {
  if (tagIds.length === 0) {
    return <>-</>
  }

  return (
    <OverflowContainer
      chipSize={'small'}
      lineHeight={16}
      maxLines={1}>
      {tagIds.map((tagId, index) => (
        <Tag
          key={tagId}
          href={[
            CONFERENCE_LIST_PATH,
            filterModelToQueryString<ConferenceEditionListItem, 'exhibitorTagIds'>([
              filterValueGroup(
                filterValueItem('exhibitorTagIds', autocompleteIncludeFilterValue([tagId]))
              ),
            ]),
          ].join('?')}
          label={tags[index]}
        />
      ))}
    </OverflowContainer>
  )
})

const columnVisibility: ColumnVisibilityModel<ConferenceEditionListItem> = {
  exhibitorTagIds: 900,
  venueCountryCode: 550,
}

const columns: Array<ColumnConfig<ConferenceEditionListItem>> = [
  {
    headerName: 'Name',
    field: 'name',
    renderCell: ({ row }) => (
      <ConferenceEditionContentLink
        conferenceEdition={row}
        conferenceId={row.id}
        conferenceName={row.name}
        logoFileUrl={row.logoFileUrl}
      />
    ),
  },
  {
    field: 'exhibitorTagIds',
    headerName: 'Tags',
    align: 'left',
    renderCell: ({ row, value }) => {
      return (
        <TagsCell
          tagIds={value}
          tags={row.exhibitorTags}
        />
      )
    },
  },
  {
    field: 'startDate',
    headerName: 'Date',
    width: 170,
    align: 'right',
    valueFormatter: ({ row, value }) => formatDateRange(value, row.endDate),
  },
  {
    field: 'venueCountryCode',
    headerName: 'Location',
    width: 140,
    align: 'right',
    valueFormatter: ({ value }) => formatCountry(value),
  },
]

interface AssetConferencesCardProps extends CardProps {
  asset: Asset
  conferences: ConferenceEditionListItem[]
}

enum ActiveConferenceTabs {
  Upcoming = 'upcoming',
  Previous = 'previous',
}

export default function AssetConferencesCard({
  asset,
  conferences,
  ...cardProps
}: AssetConferencesCardProps) {
  const upcoming = conferences.filter(({ endDate }) => isFuture(endDate))
  const previous = conferences
    .filter(({ endDate }) => isPast(endDate))
    // We want to show the most recent one first, oldest last
    .sort((conferenceA, conferenceB) => compareDesc(conferenceA.endDate, conferenceB.endDate))

  const [activeTab, setActiveTab] = useState(
    upcoming.length > 0 ? ActiveConferenceTabs.Upcoming : ActiveConferenceTabs.Previous
  )

  const [initialConferences, additionalConferences] = useSplitList(
    activeTab === ActiveConferenceTabs.Upcoming ? upcoming : previous,
    3
  )
  const [rows, showAll] = useVisibleListItems(initialConferences, additionalConferences)
  const tableRef = useRef<HTMLDivElement>(null)
  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisibility)

  const formattedToday = format(new Date(), 'yyyy-MM-dd')
  // Create the filter model, this is used in the "+ x more" button
  const assetConferenceFilterModel: FilterModel<
    ConferenceEditionListItem,
    'exhibitorAssetIds' | 'startDate'
  > = [
    filterValueGroup(
      filterValueItem(
        'startDate',
        activeTab === ActiveConferenceTabs.Upcoming ? [formattedToday] : [null, formattedToday]
      )
    ),
    filterValueGroup(
      filterValueItem('exhibitorAssetIds', autocompleteIncludeFilterValue([asset.id]))
    ),
  ]

  return (
    <Card {...cardProps}>
      <CardHeader title={'Conference participation'} />
      <CardTabs
        onChange={(_, newValue) => setActiveTab(newValue)}
        options={[
          {
            label: 'Upcoming',
            value: ActiveConferenceTabs.Upcoming,
            hidden: upcoming.length === 0,
          },
          {
            label: 'Previous',
            value: ActiveConferenceTabs.Previous,
            hidden: previous.length === 0,
          },
        ]}
        value={activeTab}
      />

      <Table
        ref={tableRef}
        columns={visibleColumns}
        disablePaddingBottomOnLastRow={!showAll}
        rows={rows}
      />

      {!showAll && (
        <ViewMoreButton
          amount={additionalConferences.length}
          href={createFilterModelQueryRoute<
            ConferenceEditionListItem,
            'exhibitorAssetIds' | 'startDate'
          >(CONFERENCE_LIST_PATH, assetConferenceFilterModel)}
        />
      )}
    </Card>
  )
}
