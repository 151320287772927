import { AssetListItem, InvestorListItem } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { formatListArgs, listFilter, listFilters } from '@gain/rpc/utils'

import {
  AutocompleteFetchOptionsFn,
  AutocompleteOption,
  AutocompleteOptionsFetcher,
  FilterConfigSearch,
} from '../filter-config/filter-config-model'
import { BuilderOptions } from '../filter-config/filter-config-util'

type FilterSearchType = 'assets' | 'investors'
type FetchOneItem = InvestorListItem | AssetListItem
type FetchOneMethod = 'data.listAssets' | 'data.listInvestors'

const filterMap = new Map<FilterSearchType, FetchOneMethod>([
  ['assets', 'data.listAssets'],
  ['investors', 'data.listInvestors'],
])

export function createFilterSearchFetchOptions<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  options: BuilderOptions<Item, FilterField, FilterConfigSearch<Item, FilterField>>
): AutocompleteFetchOptionsFn {
  const fetchOneMethod: FetchOneMethod | undefined = filterMap.get(options.searchType)

  return async (
    fetcher: AutocompleteOptionsFetcher,
    search: string | null,
    value: number[] | null
  ): Promise<AutocompleteOption[]> => {
    if (Array.isArray(value) && value.length > 0) {
      // Programmer error
      if (!fetchOneMethod) {
        throw new Error('Not supported search type')
      }

      return fetcher({
        method: fetchOneMethod,
        params: formatListArgs<FetchOneItem>({
          limit: value.length,
          filter: listFilters(listFilter('id', '=', value)),
        }),
      }).then((result): AutocompleteOption[] =>
        result.items.map((item) => ({ label: item.name, value: item.id }))
      )
    }

    if (search !== null) {
      return fetcher({
        method: 'data.search',
        params: {
          query: search,
          assets: false,
          industries: false,
          entities: false,
          investors: false,
          advisors: false,
          conferenceEditions: false,
          limit: 5,

          [options.searchType]: true,
        },
      }).then((result) => result.map((item) => ({ label: item.name, value: item.id })))
    }

    return Promise.resolve([])
  }
}
