import { useConferenceEditions } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import Grid from '@mui/material/Grid2'

import Loading from '../../../common/loading'
import AssetSimilarCompaniesCard from '../../../features/asset/asset-similar-companies-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetPageParams } from '../../utils'
import { useAssetSources } from '../route-asset-hooks'
import AssetConferencesCard from './asset-conferences-card/asset-conferences-card'
import CardCompaniesRelatedIndustries, {
  useAssetIndustryMarketSegments,
} from './card-companies-related-industries'
import CompetitorsCard from './competitors-card'

interface AssetMarketProps {
  asset: Asset
}

export default function AssetMarket({ asset }: AssetMarketProps) {
  const params = useAssetPageParams()
  const sources = useAssetSources(asset.sources, 'market')
  const swrIndustryMarketSegments = useAssetIndustryMarketSegments(params.id)
  const swrConferences = useConferenceEditions({
    filter: listFilters(listFilter('exhibitorAssetIds', '=', asset.id)),
    sort: [listSort('endDate', 'asc')],
  })

  if (swrConferences.loading || swrIndustryMarketSegments.loading) {
    return <Loading />
  }

  const hasIndustryMarketSegments = swrIndustryMarketSegments.data.items.length > 0
  const hasConferences = swrConferences.data && swrConferences.data.items.length > 0

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Market</title>
      </Head>

      <Grid
        spacing={2}
        container>
        {hasIndustryMarketSegments && (
          <Grid
            size={{
              xs: 12,
              lg: hasConferences ? 6 : 12,
            }}>
            <CardCompaniesRelatedIndustries
              segments={swrIndustryMarketSegments.data.items}
              fullHeight
            />
          </Grid>
        )}

        {swrConferences.data && swrConferences.data.items.length > 0 && (
          <Grid
            size={{
              xs: 12,
              lg: hasIndustryMarketSegments ? 6 : 12,
            }}>
            <AssetConferencesCard
              asset={asset}
              conferences={swrConferences.data.items}
              fullHeight
            />
          </Grid>
        )}

        {asset.competitors.length > 0 && (
          <Grid size={12}>
            <CompetitorsCard
              asset={asset}
              fullHeight
            />
          </Grid>
        )}

        <Grid size={12}>
          <AssetSimilarCompaniesCard
            asset={asset}
            hideAutomated={asset.profileType === AssetProfileType.Automated}
            visibleRowCount={8}
          />
        </Grid>

        {sources.length > 0 && (
          <Grid size={12}>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
