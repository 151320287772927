import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { styled } from '@mui/material/styles'
import Tab, { tabClasses, TabProps } from '@mui/material/Tab'
import Tabs, { tabsClasses, TabsProps } from '@mui/material/Tabs'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: 0,

  [`& .${tabsClasses.root}:not(.${tabsClasses.vertical})`]: {
    [`& .${tabsClasses.flexContainer}`]: {
      display: 'block',

      [`& .${tabClasses.root}:first-of-type`]: {
        marginLeft: theme.spacing(3),
      },

      [`& .${tabClasses.root}:last-of-type`]: {
        marginRight: theme.spacing(3),
      },
    },

    [`& .${tabsClasses.scrollButtons}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
    },
  },
}))

const StyledTabs = styled(Tabs)<TabsProps>({
  width: '100%',

  [`& .${tabsClasses.flexContainer}`]: {
    display: 'block',
  },
})

export interface TabOption {
  label: string
  value: string | number
  hidden?: boolean
  onClick?: TabProps['onClick']
}

export interface CardTabsProps {
  options: TabOption[]
  onChange: TabsProps['onChange']
  value: TabsProps['value']
}

export default function CardTabs({ options, onChange, value }: CardTabsProps) {
  const isBrowserExtension = useIsBrowserExtension()

  return (
    <StyledContainer>
      <StyledTabs
        allowScrollButtonsMobile={isBrowserExtension}
        onChange={onChange}
        scrollButtons={isBrowserExtension}
        value={value}
        variant={'scrollable'}>
        {options
          .filter((option) => !option.hidden)
          .map((option) => (
            <Tab
              key={option.value}
              label={option.label}
              onClick={option.onClick}
              value={option.value}
            />
          ))}
      </StyledTabs>
    </StyledContainer>
  )
}
