import { useArticleList } from '@gain/api/app/hooks'
import { ArticleListItem } from '@gain/rpc/app-model'
import { ListArgs } from '@gain/rpc/list-model'
import { ArticleType } from '@gain/rpc/shared-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

export function useListAssetArticles(assetId: number) {
  return useArticleList(
    useCallback(
      (): Partial<ListArgs<ArticleListItem>> => ({
        filter: [
          listFilter<ArticleListItem>('linkedAssetIds', '=', assetId),
          listFilter<ArticleListItem>('type', '=', ArticleType.InTheNews),
        ],
        sort: [listSort('date', 'desc')],
        limit: 100,
      }),
      [assetId]
    )
  )
}
