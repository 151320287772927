import {
  ActivityIcon,
  BadgeCheckIcon,
  BriefcaseMoneyIcon,
  CalendarIcon,
  CompanyIcon,
  ConferenceIcon,
  IndustryIcon,
  LenderIcon,
  MapPinIcon,
  PieChartIcon,
  ShuffleIcon,
  TagOutlinedIcon,
  TargetIcon,
  UserCommentIcon,
  UsersIcon,
} from '@gain/components/icons'
import {
  AdvisorListItem,
  ConferenceListItem,
  LenderListItem,
  TagListItem,
} from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import {
  AssetCustomerBaseType,
  AssetPricePositioningType,
  AssetSalesChannelType,
  CREDIT_TYPE_AND_SUBTYPE_OPTIONS,
  DealFundingRoundType,
  LENDER_TYPE_OPTIONS,
} from '@gain/rpc/shared-model'
import { formatListArgs, listFilter, listFilters } from '@gain/rpc/utils'
import { ADVISORY_ACTIVITY_OPTIONS } from '@gain/utils/advisor'
import {
  assetBusinessActivities,
  assetFteRangeCategories,
  formatAssetBusinessActivityLabel,
  formatAssetCustomerBaseLabel,
  formatAssetPricePositioningLabel,
  formatAssetSalesChannelLabel,
} from '@gain/utils/asset'
import { COUNTRY_CODE_MAP, isCountryCode, REGIONS, US_STATE_CODE_MAP } from '@gain/utils/countries'
import { dealReasons, dealStatuses } from '@gain/utils/deal'
import {
  Rating,
  RatingEsgKey,
  RatingInvestmentCriteriaKey,
  RatingInvestmentCriteriaKeyWithOverall,
  RatingOption,
} from '@gain/utils/investment-criteria'
import { SECTORS } from '@gain/utils/sector'
import { ComponentType } from 'react'

import { OWNERSHIP_TYPES } from '../../../asset/asset-ownership'
import { createFilterSearchFetchOptions } from '../filter/filter-search'
import {
  FilterCheckboxListOption,
  FilterCheckboxListOptions,
  FilterConfig,
  FilterConfigArray,
  FilterConfigAutocomplete,
  FilterConfigCheckbox,
  FilterConfigCheckboxList,
  FilterConfigCity,
  FilterConfigGeoPoint,
  FilterConfigGeoPolygon,
  FilterConfigMap,
  FilterConfigRange,
  FilterConfigRangeCurrency,
  FilterConfigSearch,
  FilterConfigSearchSubType,
  FilterConfigText,
  Option,
  OptionGroup,
  OptionValueType,
} from './filter-config-model'

export function option<ValueType extends OptionValueType>(
  label: string,
  value: ValueType,
  options: {
    explainer?: string
  } = {}
): Option<ValueType> {
  return {
    type: 'option',
    label,
    value,
    explainer: options?.explainer,
  }
}

export function optionGroup<ValueType extends OptionValueType>(
  label: string,
  options: Array<Option<ValueType> | OptionGroup<ValueType>>,
  expanded?: boolean
): OptionGroup<ValueType> {
  return {
    type: 'option-group',
    label,
    options,
    expanded,
  }
}

export function isOptionGroup<ValueType extends OptionValueType>(
  item: FilterCheckboxListOption<ValueType>
): item is OptionGroup<ValueType> {
  return item.type === 'option-group'
}

export type BuilderOptions<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  FilterOptions extends FilterConfig<Item, FilterField> = FilterConfig<Item, FilterField>
> = Omit<FilterOptions, 'id' | 'label' | 'type'>

// Primitive builders

export function autocomplete<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options: BuilderOptions<Item, FilterField, FilterConfigAutocomplete<Item, FilterField>>
): FilterConfigAutocomplete<Item, FilterField> {
  return {
    id,
    label,
    type: 'autocomplete',
    ...options,
  }
}

export function search<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options: BuilderOptions<Item, FilterField, FilterConfigSearch<Item, FilterField>>
): FilterConfigAutocomplete<Item, FilterField> {
  return autocomplete(id, label, {
    ...options,
    fetchOptions: createFilterSearchFetchOptions(options),
  })
}

export function checkbox<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigCheckbox<Item, FilterField>>
): FilterConfigCheckbox<Item, FilterField> {
  return {
    id,
    label,
    type: 'checkbox',
    ...options,
  }
}

export function checkboxList<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
>(
  id: FilterField,
  label: string,
  options: FilterCheckboxListOptions<ValueType>,
  rest?: Omit<
    BuilderOptions<Item, FilterField, FilterConfigCheckboxList<Item, FilterField, ValueType>>,
    'options'
  >
): FilterConfigCheckboxList<Item, FilterField, ValueType> {
  return {
    id,
    label,
    type: 'checkbox-list',
    options,
    ...rest,
  }
}

export function range<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  valueType: FilterConfigRange<Item, FilterField>['valueType'],
  options?: Omit<
    BuilderOptions<Item, FilterField, FilterConfigRange<Item, FilterField>>,
    'valueType'
  >
): FilterConfigRange<Item, FilterField> {
  return {
    id,
    label,
    type: 'range',
    valueType,
    ...options,
  }
}

export function rangeCurrency<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigRangeCurrency<Item, FilterField>>
): FilterConfigRangeCurrency<Item, FilterField> {
  return {
    id,
    label,
    type: 'range-currency',
    ...options,
  }
}

export function tag<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: Partial<FilterConfigAutocomplete<Item, FilterField>>
): FilterConfigAutocomplete<Item, FilterField> {
  return autocomplete<Item, FilterField>(id, label, {
    icon: TagOutlinedIcon,
    singularName: 'tag',
    pluralName: 'tags',
    fetchOptions: (fetcher, query, value) => {
      if (Array.isArray(value) && value.length > 0) {
        return fetcher({
          method: 'data.listTags',
          params: formatListArgs<TagListItem>({
            limit: value.length,
            filter: listFilters(listFilter('id', '=', value)),
          }),
        }).then((result) =>
          result.items.map((item) => ({ label: item.name, value: item.id, count: item.assetCount }))
        )
      }

      if (query !== null) {
        return fetcher({
          method: 'data.suggestTags',
          params: {
            text: query || '',
            limit: 10,
            assets: true,
          },
        }).then((result) =>
          result.map((item) => ({ label: item.name, value: item.id, count: item.assetCount }))
        )
      }

      return Promise.resolve([])
    },
    fetchSuggestions: async (fetcher, value) => {
      const associatedTags = await fetcher({
        method: 'data.listAssociatedTags',
        params: {
          tagIds: value,
          limit: 10,
          assets: true,
        },
      })

      return associatedTags.map((item) => ({
        label: item.name,
        value: item.id,
        count: item.assetCount,
      }))
    },
    ...options,
  })
}

export function text<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigText<Item, FilterField>>
): FilterConfigText<Item, FilterField> {
  return {
    id,
    label,
    type: 'text',
    ...options,
  }
}

// Builders based on primitive builders

export function customerBase<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    Object.values(AssetCustomerBaseType).map((value) =>
      option(formatAssetCustomerBaseLabel(value), value)
    )
  )
}

export function businessActivity<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    assetBusinessActivities.map((value) => option(formatAssetBusinessActivityLabel(value), value))
  )
}

export function salesChannel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    Object.values(AssetSalesChannelType).map((value) =>
      option(formatAssetSalesChannelLabel(value), value)
    )
  )
}

export function pricePositioning<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    Object.values(AssetPricePositioningType).map((value) =>
      option(formatAssetPricePositioningLabel(value), value)
    )
  )
}

export function dealStatus<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    dealStatuses.map((type) => option(type.label, type.value)),
    { icon: ActivityIcon }
  )
}

export function dealRoundType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(id, label, [
    option('Pre-Seed/Seed', DealFundingRoundType.PreSeed),
    option('Series A', DealFundingRoundType.SeriesA),
    option('Series B', DealFundingRoundType.SeriesB),
    option('Series C', DealFundingRoundType.SeriesC),
    option('Series D', DealFundingRoundType.SeriesD),
    option('Series E', DealFundingRoundType.SeriesE),
    option('Series F', DealFundingRoundType.SeriesF),
    option('Series G', DealFundingRoundType.SeriesG),
    option('Series H', DealFundingRoundType.SeriesH),
    option('Series I', DealFundingRoundType.SeriesI),
    option('Series J', DealFundingRoundType.SeriesJ),
    option('Venture', DealFundingRoundType.Venture),
    option('Other/Unknown', DealFundingRoundType.OtherUnknown),
  ])
}

export function industryScope<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    [option('United States', 'United States'), option('Europe', 'Europe')],
    { icon: TargetIcon }
  )
}

export function ownership<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    [
      option(OWNERSHIP_TYPES.private.title, OWNERSHIP_TYPES.private.id),
      optionGroup('Investor-held', [
        option(OWNERSHIP_TYPES.regular.title, OWNERSHIP_TYPES.regular.id),
        option(OWNERSHIP_TYPES.minority.title, OWNERSHIP_TYPES.minority.id),
        option(OWNERSHIP_TYPES.ventureCapital.title, OWNERSHIP_TYPES.ventureCapital.id),
      ]),
      option(OWNERSHIP_TYPES.listed.title, OWNERSHIP_TYPES.listed.id),
      optionGroup('Other', [
        option(OWNERSHIP_TYPES.subsidiary.title, OWNERSHIP_TYPES.subsidiary.id),
        option(OWNERSHIP_TYPES.bankrupt.title, OWNERSHIP_TYPES.bankrupt.id),
        option(OWNERSHIP_TYPES.government.title, OWNERSHIP_TYPES.government.id),
        option(OWNERSHIP_TYPES.other.title, OWNERSHIP_TYPES.other.id),
      ]),
    ],
    { icon: BadgeCheckIcon }
  )
}

export function region<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    REGIONS.reduce((acc, item) => {
      // Generate the region's options, which can be either subregions or countries.
      const opts = item.options.map((opt) => {
        if (isCountryCode(opt)) {
          return option(COUNTRY_CODE_MAP[opt], opt)
        }

        // If a group has only one option like US, France or Italy we don't
        // need to show a nested group.
        if (opt.options.length === 1) {
          return option(COUNTRY_CODE_MAP[opt.options[0]], opt.options[0])
        }

        return optionGroup(
          opt.name,
          opt.options.map((opt2) => option(COUNTRY_CODE_MAP[opt2], opt2))
        )
      })

      // Expand by default if the region contains subregions.
      const initialExpand = item.options.some((opt) => !isCountryCode(opt))

      return acc.concat(optionGroup(item.name, opts, initialExpand))
    }, new Array<FilterCheckboxListOption<string>>()),
    { icon: MapPinIcon, searchable: true, ...options }
  )
}

export function usState<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    Object.keys(US_STATE_CODE_MAP).map((key) => option(key, key)),
    { icon: MapPinIcon, searchable: true, ...options }
  )
}

export function sector<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    SECTORS.map((item) =>
      optionGroup(
        item.title,
        item.subsectors.map((subsector) => option(subsector.title, subsector.name))
      )
    ),
    { icon: IndustryIcon }
  )
}

export function fteRangeCategory<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    assetFteRangeCategories.map((rangeOption) => option(rangeOption.label, rangeOption.value)),
    { icon: UsersIcon }
  )
}

export function dealType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    dealReasons.map((type) => option(type.label, type.value)),
    { icon: ShuffleIcon }
  )
}

export function creditType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    CREDIT_TYPE_AND_SUBTYPE_OPTIONS.map((optionOrGroup) => {
      if ('options' in optionOrGroup) {
        return optionGroup(
          optionOrGroup.label,
          optionOrGroup.options.map((item) => option(item.label, item.value))
        )
      }

      return option(optionOrGroup.label, optionOrGroup.value)
    })
  )
}

export function lenderType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    LENDER_TYPE_OPTIONS.map((type) => option(type.label, type.value))
  )
}

export function rangeDate<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return range<Item, FilterField>(id, label, 'date', { icon: CalendarIcon })
}

export function rangeDates<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(minId: FilterField, maxId: ListItemKey<Item>, label: string) {
  return range<Item, FilterField>(minId, label, 'date', {
    icon: CalendarIcon,
    maxFilterField: maxId,
    enableReverseMinMax: true,
  })
}

export function rangeMonth<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return range<Item, FilterField>(id, label, 'month', { icon: CalendarIcon })
}

export function rangePercentage<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return range<Item, FilterField>(id, label, 'number', { suffix: '%', ...options })
}

export function rangeMultiple<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return range<Item, FilterField>(id, label, 'number', { suffix: 'x' })
}

export function rangeNumber<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: Omit<
    BuilderOptions<Item, FilterField, FilterConfigRange<Item, FilterField>>,
    'valueType' | 'prefix' | 'placeholder'
  >
) {
  return range<Item, FilterField>(id, label, 'number', { placeholder: ['Min', 'Max'], ...options })
}

export function rangeYear<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string) {
  return range<Item, FilterField>(id, label, 'number', {
    placeholder: 'YYYY',
    step: 1,
    icon: CalendarIcon,
  })
}

export function asset<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigSearchSubType<Item, FilterField>>
) {
  return search<Item, FilterField>(id, label, {
    ...options,
    icon: options?.icon || CompanyIcon,
    singularName: 'company',
    pluralName: 'companies',
    searchType: 'assets',
  })
}

export function lender<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return autocomplete<Item, FilterField>(id, label, {
    ...options,
    icon: options?.icon || LenderIcon,
    singularName: 'lender',
    pluralName: 'lenders',
    fetchOptions: (fetcher, query, value) =>
      fetcher({
        method: 'data.listLenders',
        params: formatListArgs({
          search: query ? query : undefined,
          limit: value ? value.length : 10,
          filter: value !== null ? [listFilter<LenderListItem>('id', '=', value)] : undefined,
        }),
      }).then((result) => result.items.map((item) => ({ label: item.name, value: item.id }))),
  })
}

export function investor<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigSearchSubType<Item, FilterField>>
) {
  return search<Item, FilterField>(id, label, {
    ...options,
    icon: options?.icon || BriefcaseMoneyIcon,
    singularName: 'investor',
    pluralName: 'investors',
    searchType: 'investors',
  })
}

export function conference<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return autocomplete<Item, FilterField>(id, label, {
    icon: ConferenceIcon,
    singularName: 'conference family',
    pluralName: 'conference families',
    fetchOptions: (fetcher, query, value) =>
      fetcher({
        method: 'data.listConferences',
        params: formatListArgs({
          search: query ? query : undefined,
          limit: value ? value.length : 10,
          filter: value !== null ? [listFilter<ConferenceListItem>('id', '=', value)] : undefined,
        }),
      }).then((result) => result.items.map((item) => ({ label: item.name, value: item.id }))),
  })
}

export function advisor<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: BuilderOptions<Item, FilterField>) {
  return autocomplete<Item, FilterField>(id, label, {
    ...options,
    icon: UserCommentIcon,
    singularName: 'advisor',
    pluralName: 'advisors',
    fetchOptions: (fetcher, query, value) =>
      fetcher({
        method: 'data.listAdvisors',
        params: formatListArgs({
          search: query ? query : undefined,
          limit: value ? value.length : 10,
          filter: value !== null ? [listFilter<AdvisorListItem>('id', '=', value)] : undefined,
        }),
      }).then((result) => result.items.map((item) => ({ label: item.name, value: item.id }))),
  })
}

export function advisedOnFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(id: FilterField, label: string, options?: { icon?: ComponentType }) {
  return checkboxList<Item, FilterField>(
    id,
    label,
    ADVISORY_ACTIVITY_OPTIONS.map((advisorAdvisedOnOption) =>
      option(advisorAdvisedOnOption.label, advisorAdvisedOnOption.value)
    ),
    { icon: options?.icon || PieChartIcon }
  )
}

function ratingOptionsToOption(ratingOptions: RatingOption[]): Option<number>[] {
  return ratingOptions.map((ratingOption) => option(ratingOption.label, ratingOption.value))
}

export function rating<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  Key extends RatingInvestmentCriteriaKeyWithOverall | RatingEsgKey =
    | RatingInvestmentCriteriaKey
    | RatingEsgKey
>(
  id: FilterField,
  item: Rating<Key>,
  { label }: { label?: string } = {}
): FilterConfigRange<Item, FilterField> {
  return range<Item, FilterField>(id, label || item.label, 'number', {
    options: ratingOptionsToOption(item.options),
  })
}

export function geoPoint<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigGeoPoint<Item, FilterField>>
): FilterConfigGeoPoint<Item, FilterField> {
  return {
    id,
    label,
    type: 'geo-point',
    ...options,
  }
}

export function geoPolygon<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  options?: BuilderOptions<Item, FilterField, FilterConfigGeoPolygon<Item, FilterField>>
): FilterConfigGeoPolygon<Item, FilterField> {
  return {
    id,
    label,
    type: 'geo-polygon',
    ...options,
  }
}

export function city<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  id: FilterField,
  label: string,
  regionField: ListItemKey<Item> | null,
  countryCodeField: ListItemKey<Item>,
  options?: Omit<
    BuilderOptions<Item, FilterField, FilterConfigCity<Item, FilterField>>,
    'regionField' | 'countryCodeField'
  >
): FilterConfigCity<Item, FilterField> {
  return {
    id,
    label,
    regionField,
    countryCodeField,
    type: 'city',
    ...options,
  }
}

// Other utilities

export function createFilterMap<
  Item extends object,
  FilterKey extends readonly ListItemKey<Item>[] = readonly ListItemKey<Item>[]
>(...filters: FilterConfigArray<Item, FilterKey>): FilterConfigMap<Item, FilterKey[number]> {
  return filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.id]: filter,
    }),
    {} as FilterConfigMap<Item, FilterKey[number]>
  )
}
